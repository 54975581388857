import api from '@/api'
import format from '@/utils/format'

const state = {
	reportTitle: '',
	reportInfo: {}, // 전체 리포트에 필요한 정보
	categoryInfo: { // 시험항목 정보
		list: [],
		type: '',
	},
	combinedPages: [], // 실제 html 페이지 배열. 시료 수 + 시험 항목 수 로 결정됨. 
	sampleList: [], // 시료 정보
	isBringSample: false,
	separateArr: [],
	standardOptions: []
}

const getters = {
	type: state => state.type,
	reportInfo: state => {
		return state.reportInfo
	},
	projectInfo: state => state.projectInfo,
	categoryInfo: state => state.categoryInfo,
	sampleList: state => state.sampleList,
	testResult: state => state.testResult,
	isBringSample: state => {
		return state.reportInfo.sample?.sampleDeliveryWay?.includes('지참');
	},
	combinedPages: state => state.combinedPages,
	separateArr: state => state.separateArr,
	standardOptions: state => state.standardOptions
}

const mutations = {
	setReport: (state, data) => {
		if(data.isChangeStandard) {
			state.reportInfo.category = [];
			state.reportInfo = {...state.reportInfo, 
				category: matchedCategory(
					state.reportInfo.list, 
					data.category?.list ?? data.category, 
					data.category.type
				)
			}
		} else {
			state.reportInfo = {  
				list: data.sampleList,
				category: matchedCategory(
					data.sampleList, 
					data.category?.list ?? data.category, 
					data.category.type
				),
				env: data.env,
				code : data.project.code,
				project : {...data.project},
				result: data.resultList,
				sample: data.sampleInfo,
				type: data.category.type,
				users: data.users
			}
		}
		//시험방법 1개일 때는 그대로 텍스트, 그 이상일 때에는 배열로 보여주기 - 이후 해당 시험일지 배열을 통해 하단의 시험방법 텍스트를 번호로 대치해서 보여줘야 함
		const legalText = {
			"토양오염공정시험기준": "(국립환경과학원고시 제2022-38호)",
			"실내공기질공정시험기준": "(국립환경과학원고시 제2023-1호)",
			"수질오염공정시험기준": "(국립환경과학원고시 제2024-72호)"
		};
		let sampleTestMethodArray = [];
		// if(typeof state.reportInfo?.sample?.sampleTestMethod === 'object') {
		// 	sampleTestMethodArray = state.reportInfo?.sample?.sampleTestMethod
		// } else if(state.reportInfo?.sample?.sampleTestMethod) {
		// 	sampleTestMethodArray = state.reportInfo?.sample?.sampleTestMethod?.split(',');
		// }
		sampleTestMethodArray = sampleTestMethodArray.map((item, index, arr) => {
			// 먼저 앞뒤 공백을 제거합니다
			let trimmedItem = item.trim();
			// legalText 객체의 key와 일치하면 값(고시 정보)을 뒤에 붙여줍니다
			if (legalText[trimmedItem]) {
				trimmedItem += legalText[trimmedItem];
			}
			if(arr.length > 1) {
				const numberMark = String.fromCodePoint(0x2460 + index);
				return `${numberMark} ${trimmedItem}`;
			}
			// 한 개만 있을 경우 번호 없이 그대로 반환
  			return trimmedItem;
		})
		state.reportInfo.sample.sampleTestMethod = sampleTestMethodArray;

		//시험방법이 2개 이상일 경우 각 시험항목에 해당하는 시험방법 텍스트를 번호(①) 로 돌려받기
		const matchMethodToNum = (str) => {
			const target = state.reportInfo.sample.sampleTestMethod.find((item) => item.includes(str));

			if(!target) {
				return str
			}
			if(state.reportInfo.type === 'WATER') {
				return str
			}
			const mark = target.split(" ")[0].trim();
			return mark;
		}

		//unit, method 값 매칭해주기
		state.reportInfo.category = state.reportInfo.category.map(el => {
			//특정 시료항목만 그룹을 지어주는 형태. 치환성양이온, 페놀류
			if(el.parent_id === 'SOIL_TEST_040' || el.parent_id === 'SOIL_TEST_016') {
				const result = state.categoryInfo.list.filter(item => item.testId == el.parent_id);
				if(result) {
					el.groupName = result[0]?.name;
				}
			}
			state.reportInfo.result.forEach(item => {
				if(item.testId === el.testId) {
					el.unit = item.testResultUnit;
				}
				if(item.testName.includes(el.name) || item.testName.includes(el.search_name)) {
					el.list = item.sampleList;
				}
			})
			state.reportInfo.sample?.testDetail?.list?.forEach(item => {
				if(item.testCategoryId === el.testId) {
					//각 시료에 따른 항목 value 매칭해주기
					el.itemValue = el?.list?.filter(sample => sample.name == item.sampleName)[0];
					//반띄움 기능 추가
					el.itemValue.value = format.makeHalfSpacing(el.itemValue.value);
					el.itemValue.standValue = format.makeHalfSpacing(el.itemValue.standValue);
					el.report_method = state.reportInfo?.sample?.sampleTestMethod.length > 1 ? matchMethodToNum(el.report_method) : el.report_method;
				}
			})

			//반띄움 기능 추가
			el.criteria_area_1 = format.makeHalfSpacing(el.criteria_area_1);
			el.criteria_area_2 = format.makeHalfSpacing(el.criteria_area_2);
			el.criteria_area_3 = format.makeHalfSpacing(el.criteria_area_3);
			return el;
		});
		console.log(state.reportInfo.category)
	},	
	setData: (state, data) => {
		state.categoryInfo = data.category;
		state.sampleList = data.sampleList;
		state.type = data.category.type;
	},
	setCombinedPages(state, data) {
		const {listSize, categorySize, detail} = data;
		let result = [];
		let listPages = [];
		let categoryPages = [];
		let info = {...state.reportInfo}
		if(detail) {
			info = detail;
		}
		//페이지별 시료명 분리. 가로 / 세로 별로 허용한 숫자보다 개수가 넘어갈 경우 페이지를 넘기는 형식으로 진행
		//시료 항목별 
		for (let i = 0; i < Math.ceil(info.list.length / listSize); i++) {
			listPages.push(info.list.slice(i * listSize, (i + 1) * listSize));
		}
		for (let j = 0; j < Math.ceil(info.category.length / categorySize); j++) {
			categoryPages.push(info.category.slice(j * categorySize, (j + 1) * categorySize));
		}

		for(let i = 0; i < listPages.length; i++) {
			for(let j = 0; j < categoryPages.length; j++) {
				result.push({
					categoryPage: categoryPages[j],
					listPage: listPages[i].map(el => ({...el, index: i, title: `${state.reportInfo.code}-${i+1}`}))
				})
			}
		}
		state.combinedPages = [...result]
	},
	setSeparateArr(state, data) {
		//분리 발행 목록 배열
		//임시저장용이기 때문에 modal 처음 보일 때에는 reset
		if(data) {
			// state.separateArr = [data, ...state.separateArr]
			state.separateArr = [JSON.parse(JSON.stringify(data)), ...state.separateArr]
		} else {
			state.separateArr = []
		}
	},
	setStandardOptions(state, data) {
		state.standardOptions = [...data]
	}
}

function matchedCategory(list, category, type) {
	let arr = JSON.parse(JSON.stringify(list));
	arr = arr.map(el => {
		if(el.testResult) {
			return Object.keys(el.testResult).map(testId => {
				const testInfo = el.testResult[testId]
				return testInfo.isTest ? testId : ''
			})
		}
	});
	arr.forEach(function (item, index) {
		if(index === 0) return;
		const target = arr[0];
		target?.forEach((testId, index) => {
			if(item[index] !== '' || target[index] !== '') {
				target[index] = item[index] !== '' ? item[index] : target[index]
			}
		})
	});
	const showKeys = arr[0] ?? [];
	//이름 reform - 수질은 lab_name, 다른 타입은 search_name으로
	return category?.filter(el => showKeys.includes(el.testId) && el.type !== 'group')?.map(el => ({
		...el,
		init_name: el.name,
		name: el.lab_name,
	})) ?? []
}

const actions = {
	loadReportInfo: async ({commit, dispatch}, projectId) => {
		//프로젝트 상세 정보
		const project = await api.project.getInfo(projectId)
		//시험항목(카테고리) 정보
		const category = await api.project.getCategory(projectId)
		//시료 리스트
		const sampleList = await api.project.getSampleList(projectId, false)
		//시료 정보
		const sampleInfo = await api.project.getSample(projectId)
		//시험 결과 
		const resultList = await api.project.getTestResult(projectId);
		//시험 환경
		const env = await api.project.getTestResultInfo(projectId);

		const userKeys = ['sampleCollector', 'master', 'researcher'];
		let users = {};

		function blobToBase64(blob) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.onerror = reject;
				reader.readAsDataURL(blob);
			});
		}

		//시료채취자, 시험자, 책임자 이미지 가져오기
		for(let i = 0; i < userKeys.length; i++) {
			const key = userKeys[i]
			users[key] = await api.user.getTestUserInfo(projectId, key);
			const res = await api.user.getUserSignImg(users[key].id);
			users[key].sign = await blobToBase64(res.data);
		}

		//접수자 정보 가져오기
		users['projectUser'] = {};
		users['projectUser'].name = project.detail.projectUser
		const projectUserImg = await api.user.getUserSignImg(project.detail.projectUserId)
		users['projectUser'].sign = await blobToBase64(projectUserImg.data)

		//시험 항목, 시료 리스트는 가장 기본 형태로 가지고 있어야 하기 때문에 초기에 따로 저장
		await commit('setData', {sampleList: sampleList, category: category})
		await commit('setReport', {project: project, category: category, sampleList: sampleList, sampleInfo: sampleInfo, resultList: resultList, env: env, users: users})
	},
	getCombinedPages: async ({commit, dispatch}, payload) => {
		commit('setCombinedPages', payload)
	},
	getStandardOptions: async({commit}, type) => {
		const res = await api.project.getStandardList(type);
		console.log(res)
		await commit('setStandardOptions', res.list)
	},
	changeStandardData: async({commit, state}, payload) => {
		const { projectId, date } = payload
		const res = await api.project.getCategory(projectId, date)
		await commit('setReport', {category: res, isChangeStandard: true})
	}
}

export default { namespaced: true, state, getters, mutations, actions }