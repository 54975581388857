class Project {
	constructor(instance) {
		this.axios = instance;
	}
	//업무 리스트
	async getList(params) {
		try {
			const response = await this.axios.get(`/project/list`, {params})
	
			return response.data;
		} catch(e) {
			return false;
		}
	}
	async getFilterList() {
		const response = await this.axios.get(`/project/filter/list`)
		return response.data;
	}
	//업무 상태 총합
	async getStatusSummary(params) {
		const res = await this.axios.get(`/project/status/summary`, {params})
		return res.data;
	}
	//업무 상세
	async getInfo(id) {
		try {
			const response = await this.axios.get(`/project/${id}`)
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상태 변경
	async updateStatus(id, body) {
		try {
			const response = await this.axios.patch(`/project/${id}/status`, {...body})
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 생성
	async add(body) {
		try {
			const response = await this.axios.put(`/project`, body)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 수정
	//TODO!!
	async update(id, body) {
		try {
			const response = await this.axios.patch(`/project/${id}`, body)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//파일 id 에 맞춰 파일 정보 가져오기
	async getFile(id) {
		try {
			const response = await this.axios.get(`/file/${fileId}`)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//파일 생성
	async addFile(id, body) {
		try {
			const formData = new FormData();
			formData.append("file", body.file);
			if(body.specific) {
				formData.append("specific", body.specific);
			}
			const response = await this.axios.post(`/file/upload/project/${id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data; charset=UTF-8'
				},
			})
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//파일 삭제
	async deleteFile(id) {
		try {
			const response = await this.axios.delete(`/file/${id}`)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 생성/수정 시 자동 완성
	async loadAutoComplete(field, projectId) {
		const res = await this.axios.get(`/project/autocomplete/${field}?projectId=${projectId}`);
		return res.data;
	}

	//업무 생성/수정 - 자동문서번호 생성
	async loadAutoNum(type) {
		const res = await this.axios.get(`/project/code?type=${type}`);
		return res.data;
	}

	//업무 상세 - 회계 정보 요약
	async getSettleSummary(id) {
		try {
			const response = await this.axios.get(`/project/${id}/settle/summary`)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 회계 목록
	async getSettleList(id) {
		try {
			const response = await this.axios.get(`/project/${id}/settle/list`)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 회계 정보 추가
	async addSettle(id, body) {
		try {
			const response = await this.axios.put(`/project/${id}/settle`, body)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 회계 정보 삭제
	async deleteSettle(id, settleId) {
		try {
			const response = await this.axios.delete(`/project/${id}/settle/${settleId}`)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 시료 정보 가져오기
	async getSample(id) {
		try {
			const response = await this.axios.get(`/project/${id}/sample/description`)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 시료 리스트 가져오기
	async getSampleList(id, isGroup) {
		try {
			const response = await this.axios.get(`/project/${id}/sample/list`, {
				params : {
					group : isGroup
				}
			})
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 시료 수정하기
	async updateSample(id, body) {
		try {
			const response = await this.axios.patch(`/project/${id}/sample/description`, body)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 시험 정보 가져오기
	async getTest(id) {
		try {
			const response = await this.axios.get(`/project/${id}/sample/test`)
			
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 시험 정보 입력 (등록, 수정)
	async updateTest(id, body) {
		try {
			const response = await this.axios.patch(`/project/${id}/sample/test`, body)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 - 시료 등록/수정하기 - step2. 시험 항목 가져오기
	async getCategory(id, date) {
		try {
			const response = await this.axios.get(`/project/${id}/sample/test/info`, {
				params : {
					applyDate : date
				}
			})
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 시험일지 - 기기리스트 가져오기
	async getMachine() {
		try {
			const response = await this.axios.get(`/project/machine/list`)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 시험일지 - 입력해야하는 시료 목록 가져오기
	async getSampleResultList(projectId, testId) {
		const res = await this.axios.get(`/project/${projectId}/test/${testId}/report/sample/list`)
		return res.data;
	}
	//업무 상세 - 시험일지 - 일지 form data 가져오기
	async getJournalForm(id) {
		try {
			const response = await this.axios.get(`/project/test/${id}/form`)
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 시험일지 - 입력된 시험 결과 가져오기
	async getResult(projectId, testId) {
		try {
			const response = await this.axios.get(`/project/${projectId}/test/${testId}`)
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 시험일지 - 시험결과 저장하기
	async updateJournalResult(projectId, testId, body, autoSave) {
		let response;
		try {
			if(autoSave) {
				response = await this.axios.patch(`/project/${projectId}/test/${testId}?autoSave=${autoSave}`, body)
			} else {
				response = await this.axios.patch(`/project/${projectId}/test/${testId}`, body)
			}
			return response.data
		}  catch(e) {
			return false
		}
	}
	//업무 상세 - 시험일지 - 시험일지 목록 가져오기
	async getTestJournal(testId) {
		try {
			const res = await this.axios.get(`/project/test/${testId}/list`)
			return res.data;
		} catch(e) {
			return false
		}
	}

	//업무 상세 - 시험일지 - 시험항목 ID로 이름 가져오기
	async getTestName(testId) {
		try {
			const res = await this.axios.get(`/project/test/${testId}/name`)
			return res.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 시험일지 - 연구실 환경 값 가져오기 (온도, 습도)
	async getEnvironment(params) {
		const res = await this.axios.get(`/project/test/lab/env`, {params})
		return res.data;
	}

	//업무 상세 - 산출문서 - 시험의뢰서 가져오기
	async getReferral(projectId) {
		try {
			const res = await this.axios.get(`/project/${projectId}/referral`)
			return res.data;
		} catch(e) {
			return false
		}
	}
	async getReport(projectId) {
		try {
			const res = await this.axios.get(`/project/${projectId}/test/report/list`)
			return res.data;
		} catch(e) {
			return false
		}
	}
	async downloadReport(reportId) {
		try {
			const res = await this.axios.get(`/project/test/report/${reportId}`)
			return res.data;
		} catch(e) {
			return false
		}
	}
	async deleteReport(reportId) {
		try {
			const res = await this.axios.delete(`/project/test/report/${reportId}`)
			return res.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 산출문서 - 시험의뢰서 개별 다운로드
	async downloadReferral(projectId, id) {
		try {
			const res = await this.axios.get(`/project/${projectId}/referral/${id}`)
			return res.data;
		} catch(e) {
			return false
		}
	}

	//업무 상세 - 산출문서 - 시험의뢰서 생성하기
	async createReferral(projectId) {
		try {
			const res = await this.axios.post(`/project/${projectId}/referral`)
			return res.data;
		} catch(e) {
			return false
		}
	} 

	//업무 상세 - 산출문서 - 시험의뢰서 개별 삭제하기
	async deleteReferral(projectId, id) {
		try {
			const res = await this.axios.delete(`/project/${projectId}/referral/${id}`)
			return res.data;
		} catch(e) {
			return false
		}
	}

	//업무 상세 - 산출문서 - 시험결과 확인하기
	async getTestResult(id) {
		const res = await this.axios.get(`/project/${id}/result`)
		return res.data;
	}

	//업무 상세 - 산출문서 - 시험결과 상단 정보 (날짜 등)
	async getTestResultInfo(id) {
		const res = await this.axios.get(`/project/${id}/result/info`)
		return res.data;
	}

	//업무 상세 - 산출문서 - 시험결과 상단 정보 (날짜 등) 보내기
	async editTestResultInfo(id, body) {
		const res = await this.axios.post(`/project/${id}/result/info`,  {...body})
		return res.data;
	}

	//업무상세 - 산출문서 - 시험성적서 가져오기
	async getDocument(id) {
		const res = await this.axios.get(`/project/${id}/document/list`)
		return res.data;
	}

	//업무상세 - 산출문서 - 시험성적서 발행 post

	async reportDocument(id, body, mode) {
		const res = await this.axios.post(`project/${id}/document/${mode}`, {...body})
		return res.data;
	}

	//업무상세 - 산출문서 - 시험성적서 삭제하기
	async deleteDocument(documentId) {
		try {
			const res = await this.axios.delete(`/project/document/${documentId}`)
			return res.data;
		} catch(e) {
			return false
		}
	}

	//업무상세 - 산출문서 - 법정기준목록 가져오기
	async getStandardList(type) {
		const res = await this.axios.get(`/project/standard/list?type=${type}`)
		return res.data;
	}

	async getType(type, params) {
		try {
			const response = await this.axios.get(`/project/list/type/${type}`, {params})
	
			return response.data;

		} catch(e) {
			return false
		}
	}
	//업무 상세 - 작업 기록 가져오기
	async getHistory(id, params) {
		try {
			const response = await this.axios.get(`/project/list/${id}`, {params})
	
			return response.data;
		} catch(e) {
			return false
		}
	}
	//업무 상세 - 작업 기록 삭제하기
	async deleteHistory(id) {
		const response = await this.axios.delete(`/project/list/${id}`, {historyId: id})
	
		return response.data;
	}
}

export default Project