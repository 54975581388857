import api from '@/api'
import format from '@/utils/format'
import status from '@/utils/status'

const state = {
	params : {
		keyword : '',
		page : 1,
		count : 30,
		filter: {}
	},
	list : [],
	count: 1,
	headers: [],
	statusSummary: [],
	filterList: {
		client: {
			title: '고객사',
			options: []
		},
		startDatetime: {
			title: '의뢰일',
			value: '',
			type: 'date'
		},
		department: {
			title: '담당 부서',
			options: []
		},
		endDatetime: {
			title: '종료 예정일',
			value: '',
			type: 'date'
		},
		isSettle: {
			title: '정산',
			options: []
		},
		researchDepartment: {
			title: '시험부서',
			options: []
		},
	},
	client: [],
}

const getters = {
	headers: state => {
		return state.headers.map(header => {
			return {
				key: header.headerKey,
				label: header.text,
				isSort: header.isSort,
				sort: header.sort,
				link: header.link,
				formatter: (value, key, item) => {
					if(key.indexOf('Date') >= 0) {
						value = format.date({date: value, onlyDate: true})
					}
					else if(key == 'status') {
						value = { text: status.text[value], color: status.color[value]}
					}
					else if(key == 'amount') {
						value = `${format.number(value)}원`
					}
					else if(key == 'assignee' || key == 'assigneeResearcher') {
						value = value?.name;
					}
					return value;
				}
			}
		})
	},
	list: state => {
		return state.list.map(item => {
			item.isNew = item.createDatetime >= format.date({date: new Date(), onlyDate: true}) ? true : false
			item.amountModal = '정산입력'
			return item
		})
	},
	count: state => state.count,
	params: state => state.params,
	filterList: state => state.filterList,
	statusSummary: state => state.statusSummary
}

const mutations = {
	setList: (state, data) => {
		state.list = [...data.table.list]
		state.count = data.table.count
		state.headers = [...data.table.header]
	},
	setParams: (state, data) => {
		state.params = {...state.params, ...data}
	},
	resetParams: (state) => {
		state.params = {
			keyword : '',
			page : 1,
			count : 30,
			filter: {}
		}
	},
	setFilterList: (state, data) => {
		let keys = Object.keys(data);
		let result = {}
		keys.forEach(key => {
			result[key] = {}
			result[key].title = status.filter[key]
			result[key].options = data[key]
		})
		state.filterList = {...state.filterList, ...result}
	},
	setClientList: (state, data) => {
		state.client = [...data.table.list]
	},
	setStatusSummary: (state, data) => {
		let result = [];
		let keys = Object.keys(data);
		result.push({
			text: '전체',
			value: ''
		})
		keys.forEach((key) => {
			result.push({
				value: key,
				text: `${status.text[key]}(${data[key]})`
			})
		})
		state.statusSummary = result;
	}
}

// async 됨 
const actions = {
	loadList: async ({commit, state}) => {
		const res = await api.project.getList(state.params);
		commit('setList', res)
	},
	loadFilterList: async({commit, state}) => {
		const res = await api.project.getFilterList();
		commit('setFilterList', res)
	},
	loadClientList: async({commit, state}) => {
		const res = await api.client.getList(state.params);
		commit('setClientList', res)
	},
	loadStatus: async ({commit, state}, params) => {
		const res = await api.project.getStatusSummary(params)
		commit('setStatusSummary', res)
	}
}


export default { namespaced: true, state, getters, mutations, actions }